function openInNewTab(url) 
{ 
    var win = window.open(url, '_blank'); 
    win.focus(); 
}

function CreatePromoImage(divToAdd:HTMLDivElement, promoUrl:string, describePromo:string, targetUrl:string)
{
    const divWidth = divToAdd.clientWidth;
    const divHeight = divToAdd.clientHeight;
    var img = document.createElement('img');

    const img1 = img;
    img.alt = describePromo;
    img.hidden = true;
    img.onload = function(ev: Event) {            
        const ratio = img1.height/img1.width;
        const w = (divWidth*0.9);
        const h = divHeight*0.9;
        img1.style.position = "relative";
        img1.style.boxShadow = "2px 2px 5px black";
        if (w*ratio<h)            
        {
            img1.style.width = ""+w+"px";
            img1.style.height = ""+(w*ratio)+"px";    
            img1.style.top = ""+((divHeight-(w*ratio))/2)+"px";
        }
        else
        {
            img1.style.width = ""+h/ratio+"px";
            img1.style.height = ""+h+"px";    
            img1.style.top = ""+((divHeight-(h))/2)+"px";
        }
        img1.hidden = false;
    }        
    const imgUrl = promoUrl;//"games/Promo.png";
    img.style.cursor = "pointer";
    img.onclick = function(ev: MouseEvent) {
        openInNewTab(targetUrl/*'https://apps.apple.com/us/app/tank-golfing/id1530859068'*/);
    }
    img.src = imgUrl;
    divToAdd.appendChild(img);
}

function CreateScreenshotTable(divToAdd:HTMLDivElement, screenshotUrls:{url: string, description: string}[])
{    
    const table = document.createElement('table');
    const divWidth = divToAdd.clientWidth;
    const divHeight = divToAdd.clientHeight;
    table.style.width = ""+divWidth+"px";
    table.style.borderSpacing = "0px";
    const urlArray = screenshotUrls;//["games/screnshots/screen_1920x1080_2020-09-07_22-36-49.png", "games/screnshots/screen_1920x1080_2020-09-07_22-36-56.png", "games/screnshots/screen_1920x1080_2020-09-07_22-36-58.png"];
    for (var i=0; i<urlArray.length && i<3; i++)
    {
        var td = document.createElement('td');
        td.style.textAlign = "center";
        td.style.border = "0px";
        var img = document.createElement('img');
        const img1 = img;
        const paddingLeft = 2;
        img.hidden = true;
        img.onload = function(ev: Event) {            
            const ratio = img1.height/img1.width;
            const w = ((divWidth/3)*0.9)-paddingLeft*2;
            const h = divHeight*0.9;
            if (w*ratio<h)            
            {
                img1.style.width = ""+w+"px";
                img1.style.height = ""+(w*ratio)+"px";    
                img1.style.top = ""+((divHeight-(w*ratio))/2)+"px";
            }
            else
            {
                img1.style.width = ""+h/ratio+"px";
                img1.style.height = ""+h+"px";    
                img1.style.top = ""+((divHeight-h)/2)+"px";
            }
            img1.style.boxShadow = "2px 2px 5px black";
            img1.hidden = false;
        }        
        const imgUrl = urlArray[i].url;
        const imgAlt = urlArray[i].description;
        img.style.cursor = "pointer";
        img.alt = imgAlt;
        img.onclick = function(ev: MouseEvent) {
            var darkenScreen:HTMLDivElement = <HTMLDivElement>document.createElement('div');
            darkenScreen.style.position = "fixed";
            darkenScreen.style.width = "100%";
            darkenScreen.style.height = "100%";
            darkenScreen.style.top = "0px";
            darkenScreen.style.left = "0px";
            darkenScreen.style.backgroundColor = "rgba(30, 30, 30, 0.5)";
            darkenScreen.style.zIndex = "4";
            darkenScreen.style.textAlign = "center";
            const darkenScreen1 = darkenScreen;
            var fullImg = document.createElement('img');
            fullImg.hidden = true;
            const fullImg1 = fullImg;
            fullImg1.style.position = "relative";
            fullImg.alt = imgAlt;
            fullImg.onload = function(ev: Event) {
                const ratio = fullImg1.height/fullImg1.width;
                const w = darkenScreen1.clientWidth*0.9;
                const h = darkenScreen1.clientHeight*0.9;
                var h1 = h;
                if (w*ratio<h)            
                {
                    fullImg1.style.width = ""+w+"px";
                    fullImg1.style.height = ""+(w*ratio)+"px";    
                    h1 = w*ratio;
                }
                else
                {
                    fullImg1.style.width = ""+h/ratio+"px";
                    fullImg1.style.height = ""+h+"px";    
                }
                fullImg1.style.top = (0.5*(darkenScreen1.clientHeight-h1))+"px";
                fullImg1.hidden = false;    
            }
            fullImg.src = imgUrl;    
            darkenScreen1.appendChild(fullImg);
            darkenScreen.style.cursor = "pointer";
            darkenScreen.onclick = function(e:Event) {
                darkenScreen1.parentElement.removeChild(darkenScreen1);
            }
            document.body.appendChild(darkenScreen);
        }
        img.src = urlArray[i].url;
        td.appendChild(img);
        table.appendChild(td);
    }
    divToAdd.appendChild(table);
}


function CreateTitleDisplay(divToAdd:HTMLDivElement, redirectPath:string, titleName:string, targetUrl:string, iconUrl:string, promoUrl: string, promoAlt:string, screenshotUrls:{url:string, description:string}[])
{    
    const divWidth = divToAdd.clientWidth;
    const divHeight = divToAdd.clientHeight;
    var titleDiv = document.createElement('div');
    titleDiv.style.height = ""+(divHeight*0.2)+"px";
    titleDiv.style.backgroundColor = "burlywood";
    titleDiv.innerHTML = "<table><td style='height:"+(divHeight*0.2)+"px'><img style='box-shadow: 2px 2px 5px black; background-color:white; width:64px; height:64px' src='"+redirectPath+iconUrl+"'></img></td><td style='height:"+(divHeight*0.2)+"px'><h2 style='font-size:"+(100*(divHeight*0.2)/64)+"%; text-shadow: 2px 2px 5px black; color:#FFFF88; border:solid; border-color:#CC9966; white-space: nowrap;'>"+titleName+"</h2></td></table>";
    var promoDiv = document.createElement('div');
    promoDiv.style.height = ""+(divHeight*0.6)+"px";
    promoDiv.style.textAlign = "center";
    promoDiv.style.backgroundColor = "burlywood";
//    promoDiv.style.backgroundColor = "darkgoldenrod";
    var screenshotsDiv = document.createElement('div');
//    screenshotsDiv.style.width = "100%";
    screenshotsDiv.style.height = ""+(divHeight*0.2)+"px";
    screenshotsDiv.style.backgroundColor = "burlywood";
//    screenshotsDiv.style.backgroundColor = "antiquewhite";
    divToAdd.appendChild(titleDiv);
    divToAdd.appendChild(promoDiv);
    divToAdd.appendChild(screenshotsDiv);
    CreatePromoImage(promoDiv, redirectPath+promoUrl, promoAlt, targetUrl);
    var screenshots2 = [];
    for (var i=0; i<screenshotUrls.length; i++)
        screenshots2.push({url:redirectPath+screenshotUrls[i].url, description:screenshotUrls[i].description});
    CreateScreenshotTable(screenshotsDiv, screenshots2);
}

function CreateGamesTable(divToAdd:HTMLDivElement):HTMLTableElement
{
    const cols = 2;
    const divWidth = divToAdd.clientWidth;
    const divHeight = divToAdd.clientHeight;
    var table1 = document.createElement('table');
    const tableWidth = divWidth;
    const tableHeight = divHeight;
    table1.style.width = tableWidth+"px";
    table1.style.height = tableHeight+"px";
    divToAdd.appendChild(table1);
    const redirectPath = window.location.pathname;
    console.log(redirectPath);
    var tr1 = document.createElement('tr');
    table1.appendChild(tr1);
    for (var i=0; i<4; i++)
    {
        if ((i%cols)==0 && i!=0)
        {
            tr1 = document.createElement('tr');
            table1.appendChild(tr1);
        }
        const td1 = document.createElement('td');
        tr1.appendChild(td1);
        var div1 = document.createElement('div');
        div1.style.borderWidth = "2px";
        div1.style.borderColor = "black";
        div1.style.borderStyle = "solid";
        div1.style.width = ((tableWidth/cols)-8)+"px";
        div1.style.height = ((tableHeight)-8)+"px";
        td1.appendChild(div1);
        if (i==0)
            CreateTitleDisplay(div1, redirectPath, "Golden Fall 2 Demo", "https://GoldenFall2Demo.computerrpg.com/"/*"https://apps.apple.com/us/app/tank-golfing/id1530859068"*/, "games/GoldenFall2Demo/IconV2_128.png", "games/GoldenFall2Demo/GoldenFall2DemoPromo.jpg", "Golden Fall 2 Demo Promo", [{url:"games/GoldenFall2Demo/screenshots/screen_2560x1440_2020-10-02_21-57-44.png", description:"Golden Fall 2 Demo Screenshot 1"}, {url:"games/GoldenFall2Demo/screenshots/screen_2560x1440_2020-11-24_21-02-26.png", description:"Golden Fall 2 Demo Screenshot 2"}, {url:"games/GoldenFall2Demo/screenshots/screen_2560x1440_2020-12-02_11-43-18.png", description:"Golden Fall 2 Demo Screenshot 3"}]);
        else if (i==1)
            CreateTitleDisplay(div1, redirectPath, "Tank Golfing", "https://tankgolfing.computerrpg.com/"/*"https://apps.apple.com/us/app/tank-golfing/id1530859068"*/, "games/TankGolfing/TankGolfingIcon.png", "games/TankGolfing/Promo.png", "Tank Golfing Promo", [{url:"games/TankGolfing/screenshots/screen_1920x1080_2020-09-07_22-36-49.png", description:"Tank Golfing Screenshot 1"}, {url:"games/TankGolfing/screenshots/screen_1920x1080_2020-09-07_22-36-56.png", description:"Tank Golfing Screenshot 2"}, {url:"games/TankGolfing/screenshots/screen_1920x1080_2020-09-07_22-36-58.png", description:"Tank Golfing Screenshot 3"}]);
        else if (i==2)
            CreateTitleDisplay(div1, redirectPath, "Twitch Marble", "https://twitchmarble.computerrpg.com/"/*"https://apps.apple.com/in/app/twitch-marble/id1533314036"*/, "games/TwitchMarble/IconBurn.png", "games/TwitchMarble/Promo_1024_500.png", "Twitch Marble Promo", [{url:"games/TwitchMarble/screenshots/screen_1920x1080_2020-09-23_21-36-29.png", description:"Twitch Marble Screenshot 1"}, {url:"games/TwitchMarble/screenshots/screen_1920x1080_2020-09-23_21-36-36.png", description:"Twitch Marble Screenshot 2"}, {url:"games/TwitchMarble/screenshots/screen_2960x1440_2020-09-23_20-12-10.png", description:"Twitch Marble Screenshot 3"}]);
        else if (i==3)
            CreateTitleDisplay(div1, redirectPath, "Super Annoying Jelly", "https://sajr.computerrpg.com/"/*"https://apps.apple.com/gb/app/super-annoying-jelly-runner/id1529809861"*/, "games/SuperAnnoyingJellyRunner/JellyIcon.png", "games/SuperAnnoyingJellyRunner/JellyFeature_500.png", "Super Annoying Jelly Runner Promo", [{url: "games/SuperAnnoyingJellyRunner/screenshots/screen_2160x1080_2020-08-30_18-36-36.png", description:"Super Annoying Jelly Runner Screenshot 1"}, {url:"games/SuperAnnoyingJellyRunner/screenshots/screen_2160x1080_2020-08-30_18-36-38.png", description:"Super Annoying Jelly Runner Screenshot 2"}, {url:"games/SuperAnnoyingJellyRunner/screenshots/screen_2160x1080_2020-08-30_18-36-41.png", description:"Super Annoying Jelly Runner Screenshot 3"}]);
    }
    return table1;
}

var titlesTable:HTMLTableElement = null;
window.onresize = function (ev: UIEvent) {
    if (titlesTable!=null)
    {
        titlesTable.parentElement.removeChild(titlesTable);
        titlesTable = null;
        titlesTable = CreateGamesTable(<HTMLDivElement>document.getElementById("GamesTable"));
    }
}

titlesTable = CreateGamesTable(<HTMLDivElement>document.getElementById("GamesTable"));